import FacebookIcon from 'Components/icons/facebook-icon';
import InstagramIcon from 'Components/icons/instagram-icon';
import LinkedinIcon from 'Components/icons/linkedin-icon';
import TiktokIcon from 'Components/icons/tiktok-icon';
import { SupportedLanguage } from 'Stores/LanguageStore';
import { Gutter } from 'antd/lib/grid/row';

export const DEBOUNCE_DELAY_400 = 400;
export const DROPDOWN_PAGE_SIZE = 25;
export const RETURN_URL = 'returnUrl';
export const DROPDOWN_ALL = 'all';

export const SIDENAV_WIDTH_OPEN = 105;
export const FORM_GUTTER: [Gutter, Gutter] = [10, 0];
export const PAGE_SIZE_INFINITE_LOADING = 30;
export const SCROLL_ANIMATION_BREAKPOINT = 1163;
export const MAX_SEARCH_DISTANCE_RADIUS = 50;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;

export const Z_INDEX_HIGHEST = 1000000;
export const Z_INDEX_LEVEL_1 = 1;
export const Z_INDEX_LEVEL_2 = 10;
export const Z_INDEX_LEVEL_3 = 100;
export const Z_INDEX_LEVEL_4 = 1000;
export const Z_INDEX_LEVEL_5 = 10000;

// URLS
export const LOGIN_URL = '/connexion';
export const RESET_PASSWORD_URL = '/nouveau-mot-de-passe';
export const CREATE_ACCOUNT_URL = '/nouveau-compte';
export const QUIZ_URL = '/quiz';
export const HOME_PAGE_URL = '/';
export const METIER_URL = '/metiers';
export const CONCESSIONS_URL = '/concessions';
export const TEMOIGNAGE_URL = '/temoignage';
export const ACCOUNT_URL = '/compte';
export const TERMS_CONDITIONS_URL = '/termes-et-conditions';

export const METIER_URL_REGEX = /^\/metiers\/?.*/;
export const CONCESSION_URL_REGEX = /^\/concessions\/?.*/;
export const TEMOIGNAGE_URL_REGEX = /^\/temoignages\/?.*/;

export const ANONYMOUS_CALLS_URL_REGEX = [
    /^\/candidate-token\/?.*/,
    /^\/concessions-public\/?.*/,
    /^\/makes\/?.*/,
    /^\/candidates\/forgot-password\/?.*/,
    /^\/candidates\/set-password\/?.*/,
    /^\/verify-candidate\/?.*/,
    /**satisfies /candidate but not /candidate/id */
    /^\/candidates(?!\/\w+)$/,
    /^\/recruitment-concessions-public\/?.*/,
];

//Url search param keys
export const RESET_PASSWORD_EMAIL_PARAM = 'email';
export const RESET_PASSWORD_TOKEN_PARAM = 'token';
export const ACTIVE_TAB_PARAM = 'activeTab';
export const UPDATED_QUIZ_PARAM = 'quiz';
export const CONCESSIONS_MAKES_PARAM = 'makes';
export const CONCESSIONS_SEARCH_PARAM = 'search';
export const METIER_CATEGORIES_PARAM = 'categories';

// API error codes
export const E001001 = 'E001001';
/**The verification code is invalid or expired. */
export const E012002 = 'E012002';
/**Email already in use */
export const E001003 = 'E001003';

// TIME FORMATS.  FORMAT_ is usually defined for displaying moments as text in ui not for manipulation
export const MOMENT_DEFAULT = 'YYYY-MM-DDTHH:mm:SS';
export const TWENTY_FOUR_HOUR_MINUTE = 'HH:mm';
export const TWENTY_FOUR_HOUR_MINUTE_SECOND = 'HH:mm:ss';

export const FORMAT_DAY_WRITTEN = 'dddd';
export const FORMAT_DAY_WRITTEN_ABBR = 'ddd';
export const FORMAT_DAY_COMMA_YEAR = 'DD, YYYY';
export const FORMAT_DAY = 'DD';

export const FORMAT_MONTH = 'MMMM';
export const FORMAT_SHORT_MONTH = 'MMM';
export const FORMAT_MONTH_YEAR = 'MMMM YYYY';
export const FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const FORMAT_DAY_MONTH_YEAR_SLASHES = 'DD/MM/YYYY';

export const FORMAT_TWELVE_HOUR_NO_MINUTE_AM_PM_CAPS = {
    // [SupportedLanguage.EN]: 'hh A',
    [SupportedLanguage.FR]: 'H:mm',
};
export const FORMAT_WRITTEN_DAY_DATE_MONTH_YEAR = {
    // [SupportedLanguage.EN]: 'MMMM DD YYYY',
    [SupportedLanguage.FR]: 'dddd D MMMM YYYY',
};
export const FORMAT_MONTH_WRITTEN_DATE_YEAR = {
    // [SupportedLanguage.EN]: 'MMMM DD YYYY',
    [SupportedLanguage.FR]: 'DD MMMM YYYY',
};
export const FORMAT_MONTH_WRITTEN_DATE = {
    // [SupportedLanguage.EN]: 'MMMM DD',
    [SupportedLanguage.FR]: 'DD MMMM',
};
export const FORMAT_SHORT_MONTH_DATE = {
    // [SupportedLanguage.EN]: 'MMM DD',
    [SupportedLanguage.FR]: 'DD MMM',
};
export const FORMAT_MONTH_DAY = {
    // [SupportedLanguage.EN]: 'MMMM DD',
    [SupportedLanguage.FR]: 'DD MMMM',
};
export const FORMAT_MONTH_DATE_COMMA_YEAR = {
    // [SupportedLanguage.EN]: 'MMMM DD, YYYY',
    [SupportedLanguage.FR]: 'DD MMMM, YYYY',
};
export const FORMAT_SHORT_MONTH_DATE_TIME = {
    // [SupportedLanguage.EN]: 'MMM D, h:mma',
    [SupportedLanguage.FR]: 'D MMM, H:mm',
};

export const FORMAT_SINGLE_DIGIT_TWENTY_FOUR_HOUR_MINUTE = 'H:mm';
export const FORMAT_TWELVE_HOUR_AM_PM_NO_SPACE = {
    // [SupportedLanguage.EN]: 'ha',
    [SupportedLanguage.FR]: FORMAT_SINGLE_DIGIT_TWENTY_FOUR_HOUR_MINUTE,
};
export const FORMAT_TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE = {
    // [SupportedLanguage.EN]: 'h:mma',
    [SupportedLanguage.FR]: FORMAT_SINGLE_DIGIT_TWENTY_FOUR_HOUR_MINUTE,
};
export const FORMAT_MONTH_DAY_YEAR_DASH_TWELVE_HOUR = {
    // [SupportedLanguage.EN]: 'MMMM DD YYYY - h a',
    [SupportedLanguage.FR]: 'DD MMMM YYYY - H:mm',
};

// SOCIAL MEDIA LINKS
export const SOCIAL_MEDIA_LINKS = [
    {
        href: 'https://www.facebook.com/profile.php?id=61558458261319&locale=fr_CA',
        label: 'Facebook',
        icon: FacebookIcon,
    },
    {
        href: 'https://www.instagram.com/macarriere.enconcessionnaire/',
        label: 'Instagram',
        icon: () => InstagramIcon({ fill: 'white', width: 24, height: 24 }),
    },
    {
        href: 'https://www.linkedin.com/company/ma-carri-re-en-concessionnaire/',
        label: 'LinkedIn',
        icon: LinkedinIcon,
    },
    {
        href: 'https://www.tiktok.com/@carriere.en.concession?lang=en',
        label: 'TikTok',
        icon: () => TiktokIcon({ fill: 'white', width: 24, height: 24 }),
    },
];
