import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
    CloseIcon,
    GooglemapMapIcon,
    GooglemapPhoneIcon,
    GooglemapWorldIcon,
} from 'Components/icons';
import { theme } from 'Style/theme';
import Button from 'Components/button';
import Skeleton from 'Components/Skeleton';
import { displayAddress, truncate } from 'Utils/TextUtils';
import { CONCESSIONS_URL } from 'Models/Constants';
import DefaultImg from 'Assets/Images/concession_default_img.webp';
import PostulerButton from 'Components/postuler-button';
import { observer } from 'mobx-react';
import { useService, useStores, useWindowDimensions } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFetch } from 'Hooks/use-fetch';
import { ConcessionService } from 'Services/ConcessionService';
import './concession-info-content.less';
import FancyArrowIcon from 'Components/icons/fancy-arrow-icon';
import { RecruitmentConcessionPublicDetailsDto } from 'Api/Features/Concessions/Dtos/RecruitmentConcessionPublicDetailsDto';

interface ConcessionInfoContentProps {
    concessionIds: string[];
    onClose?: () => void;
}

const ConcessionInfoContent: FunctionComponent<ConcessionInfoContentProps> = observer(
    ({ concessionIds, onClose }) => {
        const { userStore } = useStores();
        const { t } = useTranslation();
        const history = useHistory();
        const { windowWidth } = useWindowDimensions();
        const MOBILE_BREAKPOINT = 700;
        const { apiRequest, loadingStateKeys } = useFetch();

        const concessionService = useService(ConcessionService);
        const [currentConcessionIndex, setCurrentConcessionIndex] = useState(0);
        const [currentConcessionData, setCurrentConcessionData] =
            useState<RecruitmentConcessionPublicDetailsDto>();

        const fetchSpecificConcession = useCallback(
            async (id: string) => {
                const response: RecruitmentConcessionPublicDetailsDto | null = await apiRequest({
                    requestFunction: () => concessionService.getRecruitmentConcessionPublic(id),
                    requestParameters: undefined,
                    loadingStateKey: 'specificConcession',
                });

                if (response) {
                    setCurrentConcessionData(response);
                }
            },
            [concessionService, apiRequest]
        );

        useEffect(() => {
            fetchSpecificConcession(concessionIds[currentConcessionIndex]);
        }, [currentConcessionIndex, fetchSpecificConcession, concessionIds]);

        const handleNextConcession = () => {
            setCurrentConcessionIndex((prev) => {
                if (prev + 1 >= concessionIds.length) return 0;
                return prev + 1;
            });
        };

        const handlePrevConcession = () => {
            setCurrentConcessionIndex((prev) => {
                if (prev - 1 < 0) return concessionIds.length - 1;
                return prev - 1;
            });
        };

        return (
            <div className="ConcessionInfoContent">
                <Skeleton
                    placeholder={
                        <div>
                            <div className="rect" style={{ height: 600 }} />
                        </div>
                    }
                    isLoading={loadingStateKeys.has('specificConcession')}
                >
                    <div className="info-window-content">
                        <div className="top-section mb-20">
                            <img src={currentConcessionData?.mainImage?.url ?? DefaultImg} />
                            <div className="right">
                                {windowWidth > MOBILE_BREAKPOINT && (
                                    <CloseIcon
                                        onClick={onClose}
                                        width={windowWidth > MOBILE_BREAKPOINT ? 24 : 48}
                                        height={windowWidth > MOBILE_BREAKPOINT ? 24 : 48}
                                        fill={theme['surface-brand-rest-primary']}
                                        className="cursor-pointer"
                                    />
                                )}

                                <div className="name-container">
                                    <div className="text-body-large-bold">
                                        {currentConcessionData?.name}
                                    </div>
                                    <div className="text-body-small">
                                        {currentConcessionData?.makes
                                            ?.map((x) => x?.name)
                                            .join(', ')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="description mb-20"
                            dangerouslySetInnerHTML={{
                                __html: truncate(currentConcessionData?.description ?? '', 280),
                            }}
                        />

                        <div className="relative-info mb-20">
                            {currentConcessionData?.address && (
                                <div className="text-body-large text-primary">
                                    <GooglemapMapIcon fill={theme['surface-brand-rest-primary']} />
                                    {displayAddress(currentConcessionData?.address)}
                                </div>
                            )}

                            {currentConcessionData?.recruitmentContactInfo?.website && (
                                <div className="text-body-large text-primary">
                                    <GooglemapWorldIcon
                                        fill={theme['surface-brand-rest-primary']}
                                    />
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            currentConcessionData?.recruitmentContactInfo
                                                ?.website ?? ''
                                        }
                                    >
                                        {currentConcessionData?.recruitmentContactInfo?.website}
                                    </a>
                                </div>
                            )}

                            {currentConcessionData?.recruitmentContactInfo?.phoneNumber && (
                                <div className="text-body-large text-primary">
                                    <GooglemapPhoneIcon
                                        fill={theme['surface-brand-rest-primary']}
                                    />
                                    {currentConcessionData?.recruitmentContactInfo?.phoneNumber}
                                </div>
                            )}
                        </div>

                        <div className="btns">
                            <PostulerButton
                                type="red"
                                concessionIsHiring={currentConcessionData?.isHiring ?? false}
                                concessionId={currentConcessionData?.id}
                                userId={userStore.userInfo?.id}
                            />
                            <Button
                                type="white"
                                text={t('en_savoir_plus')}
                                width="auto"
                                onClick={() =>
                                    history.push(CONCESSIONS_URL + `/${currentConcessionData?.id}`)
                                }
                            />
                        </div>

                        {concessionIds.length > 1 && (
                            <div className="multi-concession-arrow-container">
                                <FancyArrowIcon
                                    width={68}
                                    height={30}
                                    direction="left"
                                    fill={theme['surface-brand-rest-primary']}
                                    className="cursor-pointer arrow"
                                    onClick={handlePrevConcession}
                                />
                                <div>
                                    <span className="text-red text-title-small-bold mr-5">
                                        {currentConcessionIndex + 1}
                                    </span>
                                    <span className="text-title-small-bold">
                                        / {concessionIds.length}
                                    </span>
                                </div>

                                <FancyArrowIcon
                                    width={68}
                                    height={30}
                                    direction="right"
                                    fill={theme['surface-brand-rest-primary']}
                                    className="cursor-pointer arrow"
                                    onClick={handleNextConcession}
                                />
                            </div>
                        )}
                    </div>
                </Skeleton>
            </div>
        );
    }
);

export default ConcessionInfoContent;
